import React, { useState } from "react";
import "../css/start.css";
import kaepsele from "../img/start/01_Kom_Kaepsele.jpg";
import komathleticum from "../img/start/01_Kom_Athleticum.jpg";
import bamberg from "../img/start/01_KOM_Kulturwerk Bamberg.jpg";
import luggesmuehle from "../img/start/01_Kom_Luggesmuehle.jpg";
import mainova from "../img/start/01_Kom_Mainova Campus.jpg";
import peking from "../img/start/01_Kom_Peking.jpg";
import zcd from "../img/start/v001.1NB.jpg";
import kombfe from "../img/start/01_Kom_BFE.jpg";
import komblockhaus from "../img/start/01_Kom_Blockhaus.jpg";
import kombow from "../img/start/01_Kom_BOW+.png";
import komgasometer from "../img/start/01_Kom_Gasometer.jpg";
import komflexpos from "../img/start/01_Kom_flexpos.jpg";
import komgeno from "../img/start/01_Kom_GENO.jpg";
import komgoldenbuoy from "../img/start/01_Kom_Golden Buoy.jpg";
import komhl from "../img/start/01_Kom_HL.jpg";
import feuerwache from "../img/start/01_Kom_Feuerwache Darmstadt.jpg"
import komleoland from "../img/start/01_Kom_Leoland.jpg";
import komkulturschule from "../img/start/01_Kom_Kulturschule.jpg";
import komneumarkt from "../img/start/01_Kom_Neumarkt.jpg";
import komqm1 from "../img/start/01_Kom_QM1.jpg";
import komrhob from "../img/start/01_Kom_RHOB.jpg";
import komstilwerk from "../img/start/01_Kom_Stilwerk.jpg";
import komumweltbank from "../img/start/01_Kom_Umweltbank.jpg";
import hochbunker from "../img/start/01_Kom_Hochbunker Bottrop.jpg";
import komfmh from "../img/start/01_Kom_FMH.jpg";
import komlola from "../img/start/03_Kom_LoLa.jpg";
import komholzhochhaus from "../img/start/04_Kom_Holzhochhaus.jpg";
import komoxford from "../img/start/05_Kom_Oxford A2.jpg";
import komsopienschule from "../img/start/06_Kom_Sophienschule.jpg";
import FGO from "../img/start/01_Kom_FGO.png";
import mhcc from "../img/start/01_Kom_MHCC.png";
import oxforda4 from "../img/start/01_Kom_Oxford A4.png";
import oxwood from "../img/start/01_Kom_Oxwood.jpg";
import steinstraße from "../img/start/01_Kom_Steinstraße.png";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

import classNames from "classnames";
import { Link } from "react-router-dom";

export interface IStartState {
  category: string;
}

export const StartComponent: React.FC<IStartState> = () => {
  const [state, setState] = useState<IStartState>({
    category: "",
  });

  const section3src = [
    { link: FGO, title: "FGO", to: "/kommunikation/fgo" },
    { link: komgasometer, title: "Gasometer", to: "/kommunikation/gasometer" },
    { link: komstilwerk, title: "Stilwerk", to: "/kommunikation/stilwerk" },
    {
      link: komumweltbank,
      title: "Umweltbank",
      to: "/kommunikation/umweltbank",
    },
    {
      link: komfmh,
      title: "FMH",
      to: "/kommunikation/fmh",
    },
    {
      link: komlola,
      title: "LoLa",
      to: "/kommunikation/lola",
    },
    {
      link: komholzhochhaus,
      title: "Holzhochhaus",
      to: "/kommunikation/holzhochhaus",
    },
    {
      link: komoxford,
      title: "Oxford",
      to: "/kommunikation/oxford",
    },
    {
      link: zcd,
      title: "ZCD",
      to: "/kommunikation/zcd",
    },
  ];

  const section2src = [
    { link: hochbunker, title: "Hochbunker Bottrop", to: "/kommunikation/hochbunker" },
    { link: oxwood, title: "Oxwood", to: "/kommunikation/oxwood" },
    { link: komneumarkt, title: "Neumarkt", to: "/kommunikation/neumarkt" },
    {
      link: komgeno,
      title: "GENO",
      to: "/kommunikation/geno",
    },

    {
      link: komgoldenbuoy,
      title: "Golden Buoy",
      to: "/kommunikation/goldenbuoy",
    },
    {
      link: komhl,
      title: "Haus Ledenhof",
      to: "/kommunikation/hausledenhof",
    },
    { link: komrhob, title: "RHOB", to: "/kommunikation/rhob" },
    { link: komleoland, title: "Leoland", to: "/kommunikation/leoland" },
    {
      link: komkulturschule,
      title: "Kulturschule",
      to: "/kommunikation/kulturschule",
    },
    { link: kombow, title: "BOW+", to: "/kommunikation/bow" },
    {
      link: mainova,
      title: "Mainova Campus",
      to: "/kommunikation/mainova",
    },


  ];

  const section1src = [
    { link: steinstraße, title: "Steinstraße", to: "/kommunikation/steinstraße" },
    { link: oxforda4, title: "Oxford", to: "/kommunikation/Oxforda4" },
    { link: feuerwache, title: "Feuerwache Darmstadt", to: "/kommunikation/feuerwache_darmstadt" },
    { link: mhcc, title: "Mauritius Health & Care Campus Steinfurt", to: "/kommunikation/mhcc" },
    {
      link: komsopienschule,
      title: "Sophienschule",
      to: "/kommunikation/sophienschule",
    },
    { link: komflexpos, title: "flex|pos", to: "/kommunikation/flexpos" },

    { link: komqm1, title: "QM1", to: "/kommunikation/qm1" },
    {
      link: komathleticum,
      title: "Athleticum",
      to: "/kommunikation/athleticum",
    },
    { link: kombfe, title: "BFE", to: "/kommunikation/bfe" },
    { link: komblockhaus, title: "Blockhaus", to: "/kommunikation/blockhaus" },
    { link: kaepsele, title: "Kaepsele", to: "/kommunikation/kaepsele" },
    { link: bamberg, title: "Kulturwerk Bamberg", to: "/kommunikation/bamberg" },
    { link: luggesmuehle, title: "Luggesmühle", to: "/kommunikation/luggesmuehle" },
    {
      link: peking,
      title: "Untere Pekingstraße",
      to: "/kommunikation/pekingstraße",
    },
  ];

  const section1 = (): JSX.Element => (
    <div
      className={classNames({
        hidden: state.category !== "",
        visible: state.category === "",
      })}
    >
      {section1src.map((src, index) => (
        <div key={index}>
          <Link to={src.to}>
            <LazyLoadImage
              src={src.link}
              alt={src.title}
              className="img"
              effect="blur"
              title={src.title}
            />
          </Link>
        </div>
      ))}
    </div>
  );

  const section2 = (): JSX.Element => (
    <div
      className={classNames({
        hidden: state.category !== "",
        visible: state.category === "",
      })}
    >
      {section2src.map((src, index) => (
        <div key={index}>
          <Link to={src.to}>
            <LazyLoadImage
              src={src.link}
              effect='blur'
              alt={src.title}
              className="img"
              title={src.title}
            />
          </Link>
        </div>
      ))}
    </div>
  );

  const section3 = (): JSX.Element => (
    <div
      className={classNames({
        hidden: state.category !== "",
        visible: state.category === "",
      })}
    >
      {section3src.map((src, index) => (
        <div key={index}>
          <Link to={src.to}>
            <LazyLoadImage
              src={src.link}
              alt={src.title}
              effect="blur"
              className="img"
              title={src.title} 
            />
          </Link>
        </div>
      ))}
    </div>
  );

  const sectionComm = (): JSX.Element => (
    <div
      className={classNames({
        hidden: state.category === "" || state.category !== "Kommunikation",
        visible: state.category === "Kommunikation",
      })}
    >
      <Link to={"/kommunikation/steinstraße"}>
        <img
          src={steinstraße}
          alt={"Steinstraße"}
          className="img"
          title={"Steinstraße"}
        />
      </Link>
      <Link to={"/kommunikation/athleticum"}>
        <img
          src={komathleticum}
          alt={"Athleticum"}
          className="img"
          title={"Athleticum"}
        />
      </Link>
      <Link to={"/kommunikation/oxforda4"}>
        <img
          src={oxforda4}
          alt={"Oxford"}
          className="img"
          title={"Oxford"}
        />
      </Link>
      <Link to={"/kommunikation/feuerwache_darmstadt"}>
        <img
          src={feuerwache}
          alt={"Feuerwache"}
          className="img"
          title={"Feuerwache Darmstadt"}
        />
      </Link>
      <Link to={"/kommunikation/mhcc"}>
        <img
          src={mhcc}
          alt={"mhcc"}
          className="img"
          title={"Mauritius Health & Care Campus Steinfurt"}
        />
      </Link>
      <Link to={"/kommunikation/bfe"}>
        <img src={kombfe} alt={"BFE"} className="img" title={"BFE"} />
      </Link>
      <Link to={"/kommunikation/blockhaus"}>
        <img
          src={komblockhaus}
          alt={"Blockhaus"}
          className="img"
          title={"Blockhaus"}
        />
      </Link>
      <Link to={"/kommunikation/bow"}>
        <img src={kombow} alt={"BOW+"} className="img" title={"BOW+"} />
      </Link>
      <Link to={"/kommunikation/gasometer"}>
        <img
          src={komgasometer}
          alt={"Gasometer"}
          className="img"
          title={"Gasometer"}
        />
      </Link>
      <Link to={"/kommunikation/flexpos"}>
        <img
          src={komflexpos}
          alt={"flex|pos"}
          className="img"
          title={"flex|pos"}
        />
      </Link>
    </div>
  );

  const sectionComm1 = (): JSX.Element => (
    <div
      className={classNames({
        hidden: state.category === "" || state.category !== "Kommunikation",
        visible: state.category === "Kommunikation",
      })}
    >      
      <Link to={"/kommunikation/hochbunker"}>
        <img
          src={hochbunker}
          alt={"Hochbunker"}
          className="img"
          title={"Hochbunker Bottrop"}
        />
      </Link>
      <Link to={"/kommunikation/goldenbuoy"}>
        <img
          src={komgoldenbuoy}
          alt={"Golden Buoy"}
          className="img"
          title={"Golden Buoy"}
        />
      </Link>
      <Link to={"/kommunikation/hausledenhof"}>
        <img
          src={komhl}
          alt={"Haus Ledenhof"}
          className="img"
          title={"Haus Ledenhof"}
        />
      </Link>
      <Link to={"/kommunikation/leoland"}>
        <img
          src={komleoland}
          alt={"Leoland"}
          className="img"
          title={"Leoland"}
        />
      </Link>
      <Link to={"/kommunikation/kulturschule"}>
        <img
          src={komkulturschule}
          alt={"Kulturschule"}
          className="img"
          title={"Kulturschule"}
        />
      </Link>
      <Link to={"/kommunikation/neumarkt"}>
        <img
          src={komneumarkt}
          alt={"Neumarkt"}
          className="img"
          title={"Neumarkt"}
        />
      </Link>
      <Link to={"/kommunikation/qm1"}>
        <img src={komqm1} alt={"QM1"} className="img" title={"QM1"} />
      </Link>
      <Link to={"/kommunikation/rhob"}>
        <img src={komrhob} alt={"RHOB"} className="img" title={"RHOB"} />
      </Link>
      <Link to={"/kommunikation/oxford"}>
        <img src={komoxford} alt={"Oxford"} className="img" title={"Oxford"} />
      </Link>
    </div>
  );

  const sectionComm2 = (): JSX.Element => (
    <div
      className={classNames({
        hidden: state.category === "" || state.category !== "Kommunikation",
        visible: state.category === "Kommunikation",
      })}
    >
      <Link to={"/kommunikation/fgo"}>
        <img
          src={FGO}
          alt={"FGO"}
          className="img"
          title={"FGO"}
        />
      </Link>
      <Link to={"/kommunikation/oxwood"}>
        <img
          src={oxwood}
          alt={"oxwood"}
          className="img"
          title={"Oxwood"}
        />
      </Link>
      <Link to={"/kommunikation/stilwerk"}>
        <img
          src={komstilwerk}
          alt={"Stilwerk"}
          className="img"
          title={"Stilwerk"}
        />
      </Link>
      <Link to={"/kommunikation/umweltbank"}>
        <img
          src={komumweltbank}
          alt={"umweltbank"}
          className="img"
          title={"Umweltbank"}
        />
      </Link>
      <Link to={"/kommunikation/fmh"}>
        <img src={komfmh} alt={"FMH"} className="img" title={"FMH"} />
      </Link>
      <Link to={"/kommunikation/lola"}>
        <img src={komlola} alt={"lola"} className="img" title={"LoLa"} />
      </Link>
      <Link to={"/kommunikation/holzhochhaus"}>
        <img
          src={komholzhochhaus}
          alt={"Holzhochhaus"}
          className="img"
          title={"Holzhochhaus"}
        />
      </Link>
      <Link to={"/kommunikation/sophienschule"}>
        <img
          src={komsopienschule}
          alt={"Sophienschule"}
          className="img"
          title={"Sophienschule"}
        />
      </Link>
      <Link to={"/kommunikation/geno"}>
        <img src={komgeno} alt={"Geno"} className="img" title={"GENO"} />
      </Link>
    </div>
  );

  const getVirText = (): JSX.Element => (
    <label className="txt">
      <span
        className="grey"
        onClick={() => setState({ ...state, category: "" })}
      >
        Hallo.
      </span>
      <span className="grey">
        Wir sind{" "}
        <span className="black">
          <Link to="/about">VIR.works</Link>
        </span>
        .
      </span>
      <span className="grey">
      Unser Job ist <span className="black" style={{cursor: 'auto'}}>Architekturkommunikation</span>.
      </span>
      <span className="grey">
        Wir freuen uns von{" "}
        <span className="black" >
          <Link to="/kontakt">Dir</Link>
        </span>{" "}
        zu Hören.
      </span>
    </label>
  );

  return (
    <>
      <div className="imgtxt_wrapper">
        {getVirText()}
        <div className="cntnr">
          {section1()}
          {section2()}
          {section3()}
          {/* {sectionNew()} */}
          {sectionComm()}
          {sectionComm1()}
          {sectionComm2()} 
        </div>
      </div>
      <footer className="footer">
        Copyright VIR.works - Göbel Fuchte GbR - 2021 |{" "}
        <Link style={{ color: "lightgray !important" }} to={"/impressum"}>
          <span style={{ color: "lightgray", marginLeft: "3px" }}>
            Impressum
          </span>
        </Link>{" "}
        |
        <Link to={"/datenschutz"}>
          <span style={{ color: "lightgray", marginLeft: "3px" }}>
            Datenschutz
          </span>
        </Link>
      </footer>
    </>
  );
};
