import { Button } from "@material-ui/core";
import React from "react";
import "../css/form.css";

export class FormComponent extends React.Component {
  constructor(props: any) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  public readonly state = {
    feedback: "",
    email: "jasper_buss@web.de",
    betreff: "",
  };

  Mailto = (email: string, subject = "", body = "", children: any) => {
    let params = subject || body ? "?" : "";
    if (subject) params += `subject=${encodeURIComponent(subject)}`;
    if (body) params += `${subject ? "&" : ""}body=${encodeURIComponent(body)}`;

    return (
      <Button
        style={{
          width: "7rem",
          border: ".1rem solid lightgray",
          margin: "1rem 0 1rem 0",
        }}
      >
        <a href={`mailto:${email}${params}`}>{children}</a>
      </Button>
    );
  };

  render() {
    return (
      <form className="mail">
        <p style={{ textAlign: "left", color: "grey" }}>Betreff</p>
        <input onChange={(e) => this.setState({ betreff: e.target.value })} />
        <p style={{ textAlign: "left", color: "grey" }}>Message*</p>
        <textarea
          onChange={(e) => this.handleChange(e)}
          placeholder="Schreib uns eine Nachricht"
          required
          value={this.state.feedback}
          style={{ resize: "both", height: "85px" }}
        />
        {this.Mailto(
          "info@vir.works",
          this.state.betreff,
          this.state.feedback,
          "Anfrage"
        )}
      </form>
    );
  }

  handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({ feedback: event.target.value });
  };
}
