import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    modal: {
      display: "flex",
      width: "100%",
      height: "100%",
      justifyContent: "center",
      outline: 0,
      backgroundColor: "white",
    },
    btn: {
      zIndex: 1000,
    },
  })
);
