import React from "react";
import { Link } from "react-router-dom";

export const DatenschutzComponent: React.FC = () => {
  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <h1>Datenschutz</h1>

      <h2 style={{ width: "unset" }}>Datenschutzerklärung</h2>

      <p>Allgemeiner Hinweis und Pflichtinformationen</p>
      <p> Benennung der verantwortlichen Stelle</p>
      <p>
        Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website
        ist:
      </p>
      <br />

      <p>VIR.works</p>
      <p>Göbel Fuchte GbR</p>
      <p>Lübecker Straße 32</p>
      <p>44135 Dortmund</p>

      <br />
      <br />

      <p style={{ maxWidth: "50%" }}>
        Die verantwortliche Stelle entscheidet allein oder gemeinsam mit anderen
        über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten
        (z.B. Namen, Kontaktdaten o. Ä.).
      </p>

      <br />
      <br />

      <h4>Widerruf Ihrer Einwilligung zur Datenverarbeitung</h4>
      <p style={{ maxWidth: "50%" }}>
        Nur mit Ihrer ausdrücklichen Einwilligung sind einige Vorgänge der
        Datenverarbeitung möglich. Ein Widerruf Ihrer bereits erteilten
        Einwilligung ist jederzeit möglich. Für den Widerruf genügt eine
        formlose Mitteilung per E-Mail. Die Rechtmäßigkeit der bis zum Widerruf
        erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.
      </p>
      <h4>Recht auf Beschwerde bei der zuständigen Aufsichtsbehörde</h4>
      <p style={{ maxWidth: "50%" }}>
        Als Betroffener steht Ihnen im Falle eines datenschutzrechtlichen
        Verstoßes ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.
        Zuständige Aufsichtsbehörde bezüglich datenschutzrechtlicher Fragen ist
        der Landesdatenschutzbeauftragte des Bundeslandes, in dem sich der Sitz
        unseres Unternehmens befindet. Der folgende Link stellt eine Liste der
        Datenschutzbeauftragten sowie deren Kontaktdaten bereit:
        <a
          href={
            "https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html"
          }
        >
          {" "}
          https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html.
        </a>
      </p>
      <h4>Recht auf Datenübertragbarkeit</h4>
      <p style={{ maxWidth: "50%" }}>
        Ihnen steht das Recht zu, Daten, die wir auf Grundlage Ihrer
        Einwilligung oder in Erfüllung eines Vertrags automatisiert verarbeiten,
        an sich oder an Dritte aushändigen zu lassen. Die Bereitstellung erfolgt
        in einem maschinenlesbaren Format. Sofern Sie die direkte Übertragung
        der Daten an einen anderen Verantwortlichen verlangen, erfolgt dies nur,
        soweit es technisch machbar ist.
      </p>
      <h4>Recht auf Auskunft, Berichtigung, Sperrung, Löschung</h4>
      <p style={{ maxWidth: "50%" }}>
        Sie haben jederzeit im Rahmen der geltenden gesetzlichen Bestimmungen
        das Recht auf unentgeltliche Auskunft über Ihre gespeicherten
        personenbezogenen Daten, Herkunft der Daten, deren Empfänger und den
        Zweck der Datenverarbeitung und ggf. ein Recht auf Berichtigung,
        Sperrung oder Löschung dieser Daten. Diesbezüglich und auch zu weiteren
        Fragen zum Thema personenbezogene Daten können Sie sich jederzeit über
        die im Impressum aufgeführten Kontaktmöglichkeiten an uns wenden.
      </p>
      <h4>SSL- bzw. TLS-Verschlüsselung</h4>
      <p style={{ maxWidth: "50%" }}>
        Aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher
        Inhalte, die Sie an uns als Seitenbetreiber senden, nutzt unsere Website
        eine SSL-bzw. TLS-Verschlüsselung. Damit sind Daten, die Sie über diese
        Website übermitteln, für Dritte nicht mitlesbar. Sie erkennen eine
        verschlüsselte Verbindung an der „https://“ Adresszeile Ihres Browsers
        und am Schloss-Symbol in der Browserzeile.
      </p>
      <h4>Kontaktformular</h4>
      <p style={{ maxWidth: "50%" }}>
        Per Kontaktformular übermittelte Daten werden einschließlich Ihrer
        Kontaktdaten gespeichert, um Ihre Anfrage bearbeiten zu können oder um
        für Anschlussfragen bereitzustehen. Eine Weitergabe dieser Daten findet
        ohne Ihre Einwilligung nicht statt. Die Verarbeitung der in das
        Kontaktformular eingegebenen Daten erfolgt ausschließlich auf Grundlage
        Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Ein Widerruf Ihrer
        bereits erteilten Einwilligung ist jederzeit möglich. Für den Widerruf
        genügt eine formlose Mitteilung per E-Mail. Die Rechtmäßigkeit der bis
        zum Widerruf erfolgten Datenverarbeitungsvorgänge bleibt vom Widerruf
        unberührt. Über das Kontaktformular übermittelte Daten verbleiben bei
        uns, bis Sie uns zur Löschung auffordern, Ihre Einwilligung zur
        Speicherung widerrufen oder keine Notwendigkeit der Datenspeicherung
        mehr besteht. Zwingende gesetzliche Bestimmungen - insbesondere
        Aufbewahrungsfristen - bleiben unberührt.
      </p>
      <h4>Google Analytics</h4>
      <p style={{ maxWidth: "50%" }}>
        Unsere Website verwendet Funktionen des Webanalysedienstes Google
        Analytics. Anbieter des Webanalysedienstes ist die Google Inc., 1600
        Amphitheatre Parkway, Mountain View, CA 94043, USA. Google Analytics
        verwendet "Cookies." Das sind kleine Textdateien, die Ihr Webbrowser auf
        Ihrem Endgerät speichert und eine Analyse der Website-Benutzung
        ermöglichen. Mittels Cookie erzeugte Informationen über Ihre Benutzung
        unserer Website werden an einen Server von Google übermittelt und dort
        gespeichert. Server-Standort ist im Regelfall die USA. Das Setzen von
        Google-Analytics-Cookies erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f
        DSGVO. Als Betreiber dieser Website haben wir ein berechtigtes Interesse
        an der Analyse des Nutzerverhaltens, um unser Webangebot und ggf. auch
        Werbung zu optimieren.
      </p>
      <h4>IP-Anonymisierung</h4>
      <p style={{ maxWidth: "50%" }}>
        Wir setzen Google Analytics in Verbindung mit der Funktion
        IP-Anonymisierung ein. Sie gewährleistet, dass Google Ihre IP-Adresse
        innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen
        Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum vor
        der Übermittlung in die USA kürzt. Es kann Ausnahmefälle geben, in denen
        Google die volle IP-Adresse an einen Server in den USA überträgt und
        dort kürzt. In unserem Auftrag wird Google diese Informationen benutzen,
        um Ihre Nutzung der Website auszuwerten, um Reports über
        Websiteaktivitäten zu erstellen und um weitere mit der Websitenutzung
        und der Internetnutzung verbundene Dienstleistungen gegenüber uns zu
        erbringen. Es findet keine Zusammenführung der von Google Analytics
        übermittelten IP-Adresse mit anderen Daten von Google statt.
      </p>
      <h4>Browser Plugin</h4>
      <p style={{ maxWidth: "50%" }}>
        Das Setzen von Cookies durch Ihren Webbrowser ist verhinderbar. Einige
        Funktionen unserer Website könnten dadurch jedoch eingeschränkt werden.
        Ebenso können Sie die Erfassung von Daten bezüglich Ihrer
        Website-Nutzung einschließlich Ihrer IP-Adresse mitsamt anschließender
        Verarbeitung durch Google unterbinden. Dies ist möglich, indem Sie das
        über folgenden Link erreichbare Browser-Plugin herunterladen und
        installieren:{" "}
        <a href={"https://tools.google.com/dlpage/gaoptout?hl=de"}>
          {" "}
          https://tools.google.com/dlpage/gaoptout?hl=de.
        </a>
      </p>
      <h4>Widerspruch gegen die Datenerfassung</h4>
      <p style={{ maxWidth: "50%" }}>
        Sie können die Erfassung Ihrer Daten durch Google Analytics verhindern,
        indem Sie auf folgenden Link klicken. Es wird ein Opt-Out-Cookie
        gesetzt, der die Erfassung Ihrer Daten bei zukünftigen Besuchen unserer
        Website verhindert: Google Analytics deaktivieren. Einzelheiten zum
        Umgang mit Nutzerdaten bei Google Analytics finden Sie in der
        Datenschutzerklärung von Google:
        <a href={"https://support.google.com/analytics/answer/6004245?hl=de"}>
          {" "}
          https://support.google.com/analytics/answer/6004245?hl=de.
        </a>
      </p>
      <h4>Auftragsverarbeitung</h4>
      <p style={{ maxWidth: "50%" }}>
        Zur vollständigen Erfüllung der gesetzlichen Datenschutzvorgaben haben
        wir mit Google einen Vertrag über die Auftragsverarbeitung
        abgeschlossen.
      </p>
      <h4>Demografische Merkmale bei Google Analytics</h4>
      <p style={{ maxWidth: "50%" }}>
        Unsere Website verwendet die Funktion “demografische Merkmale” von
        Google Analytics. Mit ihr lassen sich Berichte erstellen, die Aussagen
        zu Alter, Geschlecht und Interessen der Seitenbesucher enthalten. Diese
        Daten stammen aus interessenbezogener Werbung von Google sowie aus
        Besucherdaten von Drittanbietern. Eine Zuordnung der Daten zu einer
        bestimmten Person ist nicht möglich. Sie können diese Funktion jederzeit
        deaktivieren. Dies ist über die Anzeigeneinstellungen in Ihrem
        Google-Konto möglich oder indem Sie die Erfassung Ihrer Daten durch
        Google Analytics, wie im Punkt “Widerspruch gegen die Datenerfassung”
        erläutert, generell untersagen.
      </p>
      <div style={{ display: "flex" }}>
        Quelle: Datenschutz-Konfigurator von &nbsp;
        <a href={"https://www.mein-datenschutzbeauftragter.de/"}>
          mein-datenschutzbeauftragter.de
        </a>
      </div>
      <footer className="footer">
        Copyright VIR.works - Göbel Fuchte GbR - 2021 |{" "}
        <Link style={{ color: "lightgray !important" }} to={"/impressum"}>
          <span style={{ color: "lightgray", marginLeft: "3px" }}>
            Impressum
          </span>
        </Link>{" "}
        |
        <Link to={"/datenschutz"}>
          <span style={{ color: "lightgray", marginLeft: "3px" }}>
            Datenschutz
          </span>
        </Link>
      </footer>
    </div>
  );
};
