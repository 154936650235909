import { StartComponent } from "./components/start";
import { HomeComponent } from "./components/home";
import "./App.css";
import virlogo from "./img/Icon.png";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { AboutComponent } from "./components/about";
import { ContactComponent } from "./components/contact";
import { KommGasometerComponent } from "./components/Komm/komm_gasometer";
import { KaepseleComponent } from "./components/Komm/komm_kaepsele";
import { ZcdComponent } from "./components/Komm/komm_zcd";
import { MainovaComponent } from "./components/Komm/komm_mainova";
import { PekingComponent } from "./components/Komm/komm_pekingstraße";
import { LuggesMuehleComponent } from "./components/Komm/komm_luggesmuehle";
import { BambergComponent } from "./components/Komm/komm_bamberg";
import { KommFeuerwacheComponent } from "./components/Komm/komm_feuerwache";
import { KommAthleticumComponent } from "./components/Komm/komm_athleticum";
import { KommBfeComponent } from "./components/Komm/komm_bfe";
import { KommBlockhausComponent } from "./components/Komm/komm_blockhaus";
import { KommBowComponent } from "./components/Komm/komm_bow";
import { KommFlexposComponent } from "./components/Komm/komm_flexpos";
import { KommFmhComponent } from "./components/Komm/komm_fmh";
import { KommGenoComponent } from "./components/Komm/komm_geno";
import { KommGoldenbuoyComponent } from "./components/Komm/komm_goldenboy";
import { KommHausledenhofComponent } from "./components/Komm/komm_hausledenhof";
import { KommHolzhochhausComponent } from "./components/Komm/komm_holzhochhaus";
import { KommKulturschuleComponent } from "./components/Komm/komm_kulturschule";
import { KommLeolandComponent } from "./components/Komm/komm_leoland";
import { KommLolaComponent } from "./components/Komm/komm_lola";
import { KommNeumarktComponent } from "./components/Komm/komm_neumarkt";
import { KommOxfordComponent } from "./components/Komm/komm_oxford";
import { KommQm1Component } from "./components/Komm/komm_qm1";
import { KommRhobComponent } from "./components/Komm/komm_rhob";
import { KommSophienschuleComponent } from "./components/Komm/komm_sophienschule";
import { KommStilwerkComponent } from "./components/Komm/komm_stilwerk";
import { KommUmweltbankComponent } from "./components/Komm/komm_umweltbank";
import { CommunicationComponent } from "./components/communication";
import { ImpressumComponent } from "./components/impressum";
import { DatenschutzComponent } from "./components/datenschutz";
import CookieConsent from "react-cookie-consent";
import { KommFGO } from "./components/Komm/komm_fgo";
import { KommHochbunker } from "./components/Komm/komm_hochbunker";
import { KommMhcc } from "./components/Komm/komm_mhcc";
import { KommOxfordA4 } from "./components/Komm/komm_oxforda4";
import { KommOxwood } from "./components/Komm/komm_oxwood";
import { KommSteinstraße } from "./components/Komm/komm_steinstraße";

function App() {
  return (
    <div>
      <Router>
        <Link to="/home">
          <img className="virlogo" src={virlogo} alt="...loading" />
        </Link>
        <Switch>
          <Route path="/kommunikation/gasometer">
            <KommGasometerComponent />
          </Route>
          <Route path="/kommunikation/feuerwache_darmstadt">
            <KommFeuerwacheComponent />
          </Route>
          <Route path="/kommunikation/mhcc">
            <KommMhcc />
          </Route>
          <Route path="/kommunikation/hochbunker">
            <KommHochbunker />
          </Route>
          <Route path="/kommunikation/fgo">
            <KommFGO />
          </Route>
          <Route path="/kommunikation/athleticum">
            <KommAthleticumComponent />
          </Route>
          <Route path="/kommunikation/bfe">
            <KommBfeComponent />
          </Route>
          <Route path="/kommunikation/oxforda4">
            <KommOxfordA4 />
          </Route>
          <Route path="/kommunikation/blockhaus">
            <KommBlockhausComponent />
          </Route>
          <Route path="/kommunikation/bow">
            <KommBowComponent />
          </Route>
          <Route path="/kommunikation/flexpos">
            <KommFlexposComponent />
          </Route>
          <Route path="/kommunikation/fmh">
            <KommFmhComponent />
          </Route>
          <Route path="/kommunikation/geno">
            <KommGenoComponent />
          </Route>
          <Route path="/kommunikation/goldenbuoy">
            <KommGoldenbuoyComponent />
          </Route>
          <Route path="/kommunikation/steinstraße">
            <KommSteinstraße />
          </Route>
          <Route path="/kommunikation/oxwood">
            <KommOxwood />
          </Route>
          <Route path="/kommunikation/hausledenhof">
            <KommHausledenhofComponent />
          </Route>
          <Route path="/kommunikation/holzhochhaus">
            <KommHolzhochhausComponent />
          </Route>
          <Route path="/kommunikation/kulturschule">
            <KommKulturschuleComponent />
          </Route>
          <Route path="/kommunikation/leoland">
            <KommLeolandComponent />
          </Route>
          <Route path="/kommunikation/pekingstraße">
            <PekingComponent />
          </Route>
          <Route path="/kommunikation/lola">
            <KommLolaComponent />
          </Route>
          <Route path="/kommunikation/zcd">
            <ZcdComponent />
          </Route>
          <Route path="/kommunikation/neumarkt">
            <KommNeumarktComponent />
          </Route>
          <Route path="/kommunikation/oxford">
            <KommOxfordComponent />
          </Route>
          <Route path="/kommunikation/qm1">
            <KommQm1Component />
          </Route>
          <Route path="/kommunikation/rhob">
            <KommRhobComponent />
          </Route>
          <Route path="/kommunikation/sophienschule">
            <KommSophienschuleComponent />
          </Route>
          <Route path="/kommunikation/stilwerk">
            <KommStilwerkComponent />
          </Route>
          <Route path="/kommunikation/umweltbank">
            <KommUmweltbankComponent />
          </Route>
          <Route path="/kommunikation/kaepsele">
            <KaepseleComponent />
          </Route>
          <Route path="/kommunikation/bamberg">
            <BambergComponent />
          </Route>
          <Route path="/kommunikation/luggesmuehle">
            <LuggesMuehleComponent />
          </Route>
          <Route path="/kommunikation/mainova">
            <MainovaComponent/>
          </Route>
          <Route path="/home">
            <HomeComponent/>
          </Route>
          <Route path="/communication">
            <CommunicationComponent />
          </Route>
          <Route path="/about">
            <AboutComponent />
          </Route>
          <Route path="/kontakt">
            <ContactComponent />
          </Route>
          <Route path="/impressum">
            <ImpressumComponent />
          </Route>
          <Route path="/datenschutz">
            <DatenschutzComponent />
          </Route>
          <Route path="/">
            <StartComponent/>
          </Route>
        </Switch>
      </Router>
      <CookieConsent
        location="bottom"
        buttonText="Akzeptieren"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={150}
      >
        Cookies akzeptieren. Für weitere Informationen siehe unsere{" "}
        <a href={"/datenschutz"}>Datenschutz</a> Richtlinien
      </CookieConsent>
    </div>
  );
}

export default App;
