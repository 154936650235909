import { createStyles, makeStyles, Theme } from "@material-ui/core";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Collapse from "@material-ui/core/Collapse";
import "../css/home.css";

export interface IHomeComponentState {
  showSubLinks: boolean;
}

export const HomeComponent: React.FC<IHomeComponentState> = () => {
  const [open, setOpen] = useState(false);
  const handleClick = () => setOpen(!open);
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        width: "100%",
        maxWidth: 360,
        marginTop: "40vh",
        marginLeft: "calc(50% - 40px)",
        height: '50vh'
      },
      nested: {
        paddingLeft: theme.spacing(3),
      },
    })
  );

  const classes = useStyles();

  return (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      className={classes.root}
    >
      <ListItem>
        <Link to="/">Home</Link>
      </ListItem>
      <ListItem>
        <Link to="/about">Über Uns</Link>
      </ListItem>
      <ListItem onClick={handleClick}>
        <span className="project">Projekte</span>
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div">
          {/* <ListItem className={classes.nested}>
            <Link to="/architecture">
              <span className="sublink">Architektur</span>
            </Link>
          </ListItem> */}
          <ListItem className={classes.nested}>
            <Link to="/communication">
              <span className="sublink">Kommunikation</span>
            </Link>
          </ListItem>
        </List>
      </Collapse>
      <ListItem>
        <Link to="/kontakt">Kontakt</Link>
      </ListItem>
    </List>
  );
};
