import React from "react";
import s01 from "../img/contact/01.jpg";
import "../css/contact.css";
import { FormComponent } from "./form";
import { Link } from "react-router-dom";

export const ContactComponent: React.FC = () => {
  const getContactText = (): JSX.Element => (
    <label className="txt-info">
      <span className="grey">VIR.works</span>
      <span style={{ margin: "2px" }} className="grey">
        Studio für Architektur und Kommunikation
      </span>
      <span className="grey">Göbel Fuchte GbR</span>
      <span className="grey">
        c/o studio. - Bürogemeinschaft für Architektur
      </span>
      <span className="grey">Lübecker Straße 32</span>
      <span className="grey">44135 Dortmund</span>
      <span className="grey">0231/13740192</span>
    </label>
  );

  return (
    <>
      <div className="elem-wrapper">
        <div className="elem contact">
          <div className="info">{getContactText()}</div>
        </div>
        <div className="elem">
          <div className="mail">
            <FormComponent />
          </div>
        </div>
        <div className="elem pic">
          <img src={s01} alt="kontakt" className="contact-img"></img>
        </div>
      </div>
      <footer className="footer">
        Copyright VIR.works - Göbel Fuchte GbR - 2021 |{" "}
        <Link style={{ color: "lightgray !important" }} to={"/impressum"}>
          <span style={{ color: "lightgray", marginLeft: "3px" }}>
            Impressum
          </span>
        </Link>{" "}
        |
        <Link to={"/datenschutz"}>
          <span style={{ color: "lightgray", marginLeft: "3px" }}>
            Datenschutz
          </span>
        </Link>
      </footer>
    </>
  );
};
