import React from "react";
import komathleticum from "../img/start/01_Kom_Athleticum.jpg";
import kombfe from "../img/start/01_Kom_BFE.jpg";
import komblockhaus from "../img/start/01_Kom_Blockhaus.jpg";
import kombow from "../img/start/01_Kom_BOW+.png";
import komgasometer from "../img/start/01_Kom_Gasometer.jpg";
import komflexpos from "../img/start/01_Kom_flexpos.jpg";
import komgeno from "../img/start/01_Kom_GENO.jpg";
import komgoldenbuoy from "../img/start/01_Kom_Golden Buoy.jpg";
import komhl from "../img/start/01_Kom_HL.jpg";
import komleoland from "../img/start/01_Kom_Leoland.jpg";
import komkulturschule from "../img/start/01_Kom_Kulturschule.jpg";
import komneumarkt from "../img/start/01_Kom_Neumarkt.jpg";
import komqm1 from "../img/start/01_Kom_QM1.jpg";
import komrhob from "../img/start/01_Kom_RHOB.jpg";
import komstilwerk from "../img/start/01_Kom_Stilwerk.jpg";
import komumweltbank from "../img/start/01_Kom_Umweltbank.jpg";
import komfmh from "../img/start/01_Kom_FMH.jpg";
import komlola from "../img/start/03_Kom_LoLa.jpg";
import komholzhochhaus from "../img/start/04_Kom_Holzhochhaus.jpg";
import komoxford from "../img/start/05_Kom_Oxford A2.jpg";
import komsopienschule from "../img/start/06_Kom_Sophienschule.jpg";
import feuerwache from "../img/start/01_Kom_Feuerwache Darmstadt.jpg";
import steinstraße from "../img/start/01_Kom_Steinstraße.png";
import oxwood from "../img/start/01_Kom_Oxwood.jpg";
import oxforda4 from "../img/start/01_Kom_Oxford A4.png";
import mhcc from "../img/start/01_Kom_MHCC.png";
import FGO from "../img/start/01_Kom_FGO.png";
import hochbunker from "../img/start/01_Kom_Hochbunker Bottrop.jpg";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import "../css/start.css";

import { Link } from "react-router-dom";

export interface IStartState {
  category: string;
}

export const CommunicationComponent: React.FC<IStartState> = () => {
  const sectionComm = (): JSX.Element => (
    <div className="visible">
      <Link to={"/kommunikation/feuerwache"}>
        <LazyLoadImage
          src={feuerwache}
          alt={"Feuerwache Darmstadt"}
          className="img"
          title={"Feuerwache Darmstadt"}
        />
      </Link>
      <Link to={"/kommunikation/steinstraße"}>
        <LazyLoadImage
          src={steinstraße}
          alt={"Steinstraße"}
          className="img"
          title={"Steinstraße"}
        />
      </Link>
      <Link to={"/kommunikation/athleticum"}>
        <LazyLoadImage
          src={komathleticum}
          alt={"Athleticum"}
          className="img"
          title={"Athleticum"}
        />
      </Link>
      <Link to={"/kommunikation/bfe"}>
        <LazyLoadImage src={kombfe} alt={"BFE"} className="img" title={"BFE"} />
      </Link>
      <Link to={"/kommunikation/blockhaus"}>
        <LazyLoadImage
          src={komblockhaus}
          alt={"Blockhaus"}
          className="img"
          title={"Blockhaus"}
        />
      </Link>
      <Link to={"/kommunikation/bow"}>
        <LazyLoadImage src={kombow} alt={"BOW+"} className="img" title={"BOW+"} />
      </Link>
      <Link to={"/kommunikation/gasometer"}>
        <LazyLoadImage
          src={komgasometer}
          alt={"Gasometer"}
          className="img"
          title={"Gasometer"}
        />
      </Link>
      <Link to={"/kommunikation/flexpos"}>
        <LazyLoadImage
          src={komflexpos}
          alt={"flex|pos"}
          className="img"
          title={"flex|pos"}
        />
      </Link>
      <Link to={"/kommunikation/sophienschule"}>
        <LazyLoadImage
          src={komsopienschule}
          alt={"Sophienschule"}
          className="img"
          title={"Sophienschule"}
        />
      </Link>
      <Link to={"/kommunikation/geno"}>
        <LazyLoadImage src={komgeno} alt={"Geno"} className="img" title={"GENO"} />
      </Link>
    </div>
  );

  const sectionComm1 = (): JSX.Element => (
    <div className="visible">
      <Link to={"/kommunikation/goldenbuoy"}>
        <LazyLoadImage
          src={komgoldenbuoy}
          alt={"Golden Buoy"}
          className="img"
          title={"Golden Buoy"}
        />
      </Link>
      <Link to={"/kommunikation/oxwood"}>
        <LazyLoadImage
          src={oxwood}
          alt={"Oxwood"}
          className="img"
          title={"Oxwood"}
        />
      </Link>
      <Link to={"/kommunikation/oxforda4"}>
        <LazyLoadImage
          src={oxforda4}
          alt={"Oxford"}
          className="img"
          title={"Oxford"}
        />
      </Link>
      <Link to={"/kommunikation/hausledenhof"}>
        <LazyLoadImage
          src={komhl}
          alt={"Haus Ledenhof"}
          className="img"
          title={"Haus Ledenhof"}
        />
      </Link>
      <Link to={"/kommunikation/leoland"}>
        <LazyLoadImage
          src={komleoland}
          alt={"Leoland"}
          className="img"
          title={"Leoland"}
        />
      </Link>
      <Link to={"/kommunikation/kulturschule"}>
        <LazyLoadImage
          src={komkulturschule}
          alt={"Kulturschule"}
          className="img"
          title={"Kulturschule"}
        />
      </Link>
      <Link to={"/kommunikation/neumarkt"}>
        <LazyLoadImage
          src={komneumarkt}
          alt={"Neumarkt"}
          className="img"
          title={"Neumarkt"}
        />
      </Link>
      <Link to={"/kommunikation/qm1"}>
        <LazyLoadImage src={komqm1} alt={"QM1"} className="img" title={"QM1"} />
      </Link>
      <Link to={"/kommunikation/rhob"}>
        <LazyLoadImage src={komrhob} alt={"RHOB"} className="img" title={"RHOB"} />
      </Link>
      <Link to={"/kommunikation/oxford"}>
        <LazyLoadImage src={komoxford} alt={"Oxford"} className="img" title={"Oxford"} />
      </Link>
    </div>
  );

  const sectionComm2 = (): JSX.Element => (
    <div className="visible">
      <Link to={"/kommunikation/mhcc"}>
        <LazyLoadImage
          src={mhcc}
          alt={"mhcc"}
          className="img"
          title={"mhcc"}
        />
      </Link>
      <Link to={"/kommunikation/fgo"}>
        <LazyLoadImage
          src={FGO}
          alt={"FGO"}
          className="img"
          title={"FGO"}
        />
      </Link>
      <Link to={"/kommunikation/hochbunker"}>
        <LazyLoadImage
          src={hochbunker}
          alt={"Hochbunker"}
          className="img"
          title={"Hochbunker"}
        />
      </Link>
      <Link to={"/kommunikation/stilwerk"}>
        <LazyLoadImage
          src={komstilwerk}
          alt={"Stilwerk"}
          className="img"
          title={"Stilwerk"}
        />
      </Link>
      <Link to={"/kommunikation/umweltbank"}>
        <LazyLoadImage
          src={komumweltbank}
          alt={"umweltbank"}
          className="img"
          title={"Umweltbank"}
        />
      </Link>
      <Link to={"/kommunikation/fmh"}>
        <LazyLoadImage src={komfmh} alt={"FMH"} className="img" title={"FMH"} />
      </Link>
      <Link to={"/kommunikation/lola"}>
        <LazyLoadImage src={komlola} alt={"lola"} className="img" title={"LoLa"} />
      </Link>
      <Link to={"/kommunikation/holzhochhaus"}>
        <LazyLoadImage
          src={komholzhochhaus}
          alt={"Holzhochhaus"}
          className="img"
          title={"Holzhochhaus"}
        />
      </Link>
    </div>
  );

  const getVirText = (): JSX.Element => (
    <label className="txt">
      <Link to="/home">
        <span className="grey">Hallo.</span>
      </Link>
      <span className="grey">
        Wir sind{" "}
        <span className="black">
          <Link to="/about">VIR.works</Link>
        </span>.
      </span>
      <span className="grey">
        Wir arbeiten an {""}
        <Link to="/architecture">
          <span className="black">Architektur</span> und{" "}
        </Link>
        <Link to="/communication">
          <span className="black">Kommunikation</span>
        </Link>.
      </span>
      <span className="grey">
        Wir freuen uns von{" "}
        <span className="black">
          <Link to="/kontakt">Dir</Link>
        </span>{" "}
        zu Hören.
      </span>
    </label>
  );

  return (
    <>
      <div className="imgtxt_wrapper">
        {getVirText()}
        <div className="cntnr">
          {sectionComm()}
          {sectionComm1()}
          {sectionComm2()}
        </div>
      </div>
      <footer className="footer">
        Copyright VIR.works - Göbel Fuchte GbR - 2021 | Impressum | Datenschutz
      </footer>
    </>
  );
};
