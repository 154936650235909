import React from "react";
import s01 from "../../img/KOM/Kom_Leoland/01.jpg";
import s02 from "../../img/KOM/Kom_Leoland/02.jpg";
import "../../css/archi.css";
import classNames from "classnames";
import { Button, Modal } from "@material-ui/core";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { useStyles } from "../../styles/styles";

export interface IKommState {
  src: string;
  zoom: boolean;
  slideIndex: number;
}

export const KommLeolandComponent: React.FC<IKommState> = () => {
  const [state, setState] = React.useState<IKommState>({
    src: "",
    zoom: false,
    slideIndex: -1,
  });

  const classes = useStyles();

  const slides = [s01, s02];

  const setSliderImgIndex = (src: string) => {
    const slIndex = slides.findIndex((slide) => slide === src);
    setState((state: IKommState) => ({
      ...state,
      slideIndex: slIndex,
    }));
  };

  const handleClickForward = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    const maxIndex = slides.length - 1;
    if (state.slideIndex === maxIndex) {
      setState({
        ...state,
        slideIndex: 0,
      });
    } else {
      setState({
        ...state,
        slideIndex: state.slideIndex + 1,
      });
    }
  };

  const handleClickBackward = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    const maxIndex = slides.length - 1;
    if (state.slideIndex === 0) {
      setState({
        ...state,
        slideIndex: maxIndex,
      });
    } else {
      setState({
        ...state,
        slideIndex: state.slideIndex - 1,
      });
    }
  };

  const slideForward = (): JSX.Element => (
    <Button style={{ color: "black" }} onClick={(e) => handleClickForward(e)}>
      <ArrowForwardIosIcon />
    </Button>
  );

  const slideBackward = (): JSX.Element => (
    <Button style={{ color: "black" }} onClick={(e) => handleClickBackward(e)}>
      <ArrowBackIosIcon />
    </Button>
  );

  return (
    <div className="imgtxt-wrapper">
      <div className="imgtxt-project">
        <figure>
          <figcaption className="figcap">
            <h1>Leoland</h1>
            <span style={{ color: "grey" }}>_Kommunikation_Visualisierung</span>
            <h4>Schüring Architekten | 2018</h4>
            <span style={{ color: "grey" }}>
              Wettbewerbsbeitrag für den Neubau des „Leoland“ Münster
            </span>
          </figcaption>
          <img
            className="arcimg"
            src={s01}
            alt="leoland"
            onClick={(e) => {
              e.stopPropagation();
              setSliderImgIndex(s01);
            }}
            style={{ marginBottom: "1rem" }}
          />
          <img
            className="arcimg"
            src={s02}
            alt="leoland"
            onClick={(e) => {
              e.stopPropagation();
              setSliderImgIndex(s02);
            }}
            style={{ marginBottom: "1rem" }}
          />
        </figure>
      </div>
      <Modal className={classes.root} open={state.slideIndex !== -1}>
        <div className={classes.modal}>
          <Button
            className="btn"
            onClick={() => setState({ ...state, slideIndex: -1 })}
          >
            <span style={{ color: "black" }}>X</span>
          </Button>
          <div className="sliback">{slideBackward()}</div>
          <div
            style={{
              maxHeight: "100vh",
              overflow: "auto",
              alignSelf: "center",
            }}
          >
            <img
              src={slides[state.slideIndex]}
              className={classNames({
                modalimg: true,
                "zoom-in": state.zoom,
              })}
              alt="sopienschule"
              onClick={(e) => {
                e.stopPropagation();
                setState({ ...state, zoom: !state.zoom });
              }}
            ></img>
          </div>
          <div className="slifor">{slideForward()}</div>
        </div>
      </Modal>
    </div>
  );
};
